<template id="inventario-comprometido">
<div class="page-inventario-comprometido">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Inventario Comprometido" class="elevation-1 px-5 py-3">

          <v-card-text>
            <div id="datagrid">
                <v-alert><strong>Precaución:</strong> Utilizar la función de Descartar Comprometido solo para pruebas o desbloqueo de mercancía; ya que la forma correcta de devolver el comprometido es cancelando la venta.
                    El descartar el comprometido de manera directa podría ocasionar inconsistencias en el inventario.
                </v-alert>

                <v-row>
                  <v-col>
                      <span style="color:red;font-weight:bolder;">Hay {{ventas_sin_salida}} VENTA(S) de mayoreo a la(s) que no se ha dado salida de mercancia.</span>
                      &nbsp;
                      <v-btn x-small outlined dark color="blue darken-3" @click.native="finalizar_salida_ventas()" 
                      v-show="ventas_sin_salida>0"  title="Dar Salida a Ventas" v-tippy>
                      <v-icon>done</v-icon> Dar Salida a Ventas
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-show="traspasos_pendientes>0"><br>
                  <v-col>
                      <span style="color:red;font-weight:bolder;">Hay {{traspasos_pendientes}} TRASPASO(S) pendiente(s). Favor de verificar.</span>
                      &nbsp;
                      <v-btn x-small outlined color="blue darken-3" @click.native="finalizar_traspasos()" 
                        title="Finalizar Traspasos" v-tippy>
                        <v-icon>swap_horiz</v-icon> Finalizar Traspasos
                    </v-btn>
                  </v-col>
                </v-row>

              <v-client-table ref="vuetable" :data="items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="id_sucursal" slot-scope="props">
                  {{ get_nombre_sucursal(props.row.id_sucursal) }}
                </template>

                <template slot="codigo_barras" slot-scope="props">
                  {{ props.row.codigo_barras.join(", ") }}
                </template>

                <template slot="precio_compra" slot-scope="props">
                  <div style="text-align:right;">${{props.row.precio_compra}}</div>
                </template>

                <template slot="fecha_caducidad" slot-scope="props">
                  {{ parse_date(props.row.fecha_caducidad) }}
                </template>

                <template slot="actual" slot-scope="props">
                    <div style="text-align:center;" :class="'columnaActual celda_'+props.row._id">
                        {{parseFloat(props.row.actual).toFixed(2)}}
                    </div>
                </template>

                <template slot="comprometido" slot-scope="props">
                    <div style="text-align:center;" :class="'columnaComprometido celda_'+props.row._id">
                        {{parseFloat(props.row.comprometido).toFixed(2)}}
                    </div>
                </template>

                <template slot="actions" slot-scope="props">
                    <v-btn x-small fab dark color="green" @click.native="regresa_comprometido(props.row)" 
                      v-show="props.row.comprometido<0"  title="Regresar Comprometido a Actual" v-tippy>
                      <v-icon>undo</v-icon>
                    </v-btn>&nbsp;
                    <v-btn x-small fab dark color="pink" @click.native="descarta_comprometido(props.row)" 
                      v-show="props.row.comprometido<0"  title="Descartar Comprometido" v-tippy>
                      <v-icon>cancel</v-icon>
                    </v-btn>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

</div>
</template>


<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('t.inventarios.comprometido')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.get_sucursales();
    this.getVentasComprometidas();
    this.get_traspasos_pendientes();
  },
  data() {
    return {
      ventas_sin_salida: 0,
      traspasos_pendientes:0, 
        key:0,
        sucursales:[],
      modal_detalle: false,
      lote_detalle: [],
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      fab: [],
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Inventario Comprometido",
          disabled: true,
          href: ""
        }        
      ],
      columns: [
        "id_sucursal",
        "nombre_articulo",
        "descripcion_articulo",
        "precio_compra",
        "fecha_caducidad",
        "actual",
        "comprometido",
        "actions"
      ],
      options: {
        filterable: false,
        sortable: ["nombre_articulo",
            "descripcion_articulo","precio_compra","fecha_caducidad","actual","comprometido"],

        headings: {
            "nombre_articulo":"Artículo",
            "descripcion_articulo":"Descripción",
            "precio_compra":"Precio Compra",
            "fecha_caducidad":"Fecha Caducidad",            
            actions: "Operaciones"
        },
      },
      columns_detalle: ["id_sucursal", "cantidad", "precio_compra", "fecha_caducidad", "fecha_entrada", "actual", "comprometido"],
      options_detalle: {
        filterable: ["id_sucursal", "cantidad", "precio_compra", "actual", "comprometido"],
        sortable: ["id_sucursal", "cantidad", "precio_compra", "actual", "comprometido"],

        headings: {
          id_sucursal: "Sucursal",
          cantidad: "Cantidad",
          precio_compra: "Precio de Compra",
          fecha_caducidad: "Fecha de Caducidad",
          fecha_entrada: "Fecha de Entrada",
          actual: "Actual",
          comprometido: "Comprometido"
        },
      },
      
      modal: false,
      items: []
    }
  },
  methods: {
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {
        "type":"lotes",
        "comprometido": {
            "$lt": 0
        }
        };

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            use_index:"_design/11170fd6ddee19bf977db538eb3f13fb1ab54fc6"
          },
        }).then(async response => {
          if (response.data.docs.length) {

            await Promise.all(response.data.docs.map(async (x) => {

                await window.axios
                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + x["id_articulo"])
                    .then(responseArticulo => {
                        x["nombre_articulo"] = responseArticulo.data["nombre"];
                        x["descripcion_articulo"] = responseArticulo.data["descripcion"];
                    })
                    .catch(error => {
                        console.log("Error al buscar articulo: ", error);                    
                    });
            }));
            this.items = this.items.concat(response.data.docs);
            this.props_paginacion.total_registros = this.items.length;            
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    get_traspasos_pendientes: function(){  
        let search = {
          "selector": {
            "type":"lotes_traspasos",
            "estatus": {"$in": ["Pendiente","En Proceso de Salida","Salida de Origen","En Proceso de Entrada"]}
          },"fields":["_id"]
        };            
        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', search)
          .then(async response => { 
            if(response.data != null && response.data.docs!=null && response.data.docs.length>0){
              this.traspasos_pendientes = response.data.docs.length;
            } else {
              this.traspasos_pendientes = 0;              
            }
          }).catch(err => {
            console.log(err);
            this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al obtener los traspasos pendientes. Recargue la página o comuníquese con Soporte",
                  footer: ""
              });
          });
    },

    getVentasComprometidas:function(){
      //this.ventas_sin_salida = 2;
      //this.ventas_comprometidas = ["c2efe83a87d1ee8aa7fd0bfb4613d4c3"];
      //return; 

      window.dialogLoader.show('Espere un momento por favor..');

        window.axios
          .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_sin_salida')
          .then(async response => { 
            if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
              var rows = response.data.rows;
              this.ventas_sin_salida = response.data.rows.length;
              this.ventas_comprometidas = [];
              for(var k in response.data.rows){
                  this.ventas_comprometidas.push(response.data.rows[k].value);
              }
            } else {
              this.ventas_sin_salida = 0;
              this.ventas_comprometidas = [];                    
            }
          }).catch(err => {
            console.log(err);
            this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al obtener las ventas sin salida de mercancía. Recargue la página o comuníquese con Soporte",
                  footer: ""
              });
          }).then(()=>{
              window.dialogLoader.hide();
          });
      
    },

    get_nombre_sucursal: function(id_sucursal){
      let filtro = this.sucursales.find(e => e._id == id_sucursal)
      if(filtro){
        return filtro.nombre;
      } else {
        return "";
      }
    },
    get_sucursales: function () {
      let data = {
          "selector": {
              "type":"sucursales",
              "estatus": {
                  "$eq": "Activo"
              }
          },
          "fields": [
              "nombre", "_id"
          ]
      };

      window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
              this.sucursales = [];
              if (response.data.docs.length > 0)
                  this.sucursales = response.data.docs;                  
          })

          .catch(error => {
              this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al obtener las sucursales.",
                  footer: ""
              });
          });
  },
    
    descarta_comprometido: function(row) {
        //borra el comprometido solamente, el actual lo deja igual

       if(row.comprometido < 0){

              this.$swal({
                type: "info",
                title: "Cuidado",
                text: "Se eliminará el comprometido, sin modificar la existencia actual. ¿Desea continuar?",
                footer: "",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            }).then(resultado => {
                if (resultado.value) {
                  var comprometido = Math.abs(row.comprometido);
                  row.comprometido += comprometido;

                  delete row.nombre_articulo;
                  delete row.descripcion_articulo;
                  
                  
                  window.dialogLoader.show('Espere un momento por favor..');

                  window.axios
                      .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + row._id + '?conflicts=true', row)
                      .then(response => {
                          if (response.data.ok) {
                              row._rev = response.data.rev;    
                              
                              //Se pone la cantidad de ventas del periodo en la celda donde se selecciono articulo
                              var clave_fila = "celda_"+row._id;
                              var celda = document.querySelectorAll('.'+clave_fila);
                              for(var i = 0; i < celda.length; i++) {
                                  if(celda[i].className!=undefined && celda[i].className.indexOf("columnaComprometido") != -1 ){
                                      celda[i].innerHTML = row.comprometido;
                                  }
                              }
                          }

                      window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                          color: 'success'
                      });
                      //this.items = [];
                      //this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                      })
                      .catch(error => {
                        window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                            color: 'error'
                        });
                      }).then(() => {              
                        window.dialogLoader.hide();
                      });      
                }
            });
            
        }        
    },
    regresa_comprometido: function(row) {
        //regresar el comprometido al actual
        if(row.comprometido < 0){

              this.$swal({
                type: "info",
                title: "Cuidado",
                text: "Se regresará el comprometido a la existencia actual. ¿Desea continuar?",
                footer: "",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            }).then(resultado => {
                if (resultado.value) {
                  var comprometido = Math.abs(row.comprometido);
                  row.actual += comprometido;
                  row.comprometido += comprometido;

                  delete row.nombre_articulo;
                  delete row.descripcion_articulo;
                  
                  
                  window.dialogLoader.show('Espere un momento por favor..');

                  window.axios
                      .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + row._id + '?conflicts=true', row)
                      .then(response => {
                          if (response.data.ok) {
                              row._rev = response.data.rev;    
                              
                              //Se pone la cantidad de ventas del periodo en la celda donde se selecciono articulo
                              var clave_fila = "celda_"+row._id;
                              var celda = document.querySelectorAll('.'+clave_fila);
                              for(var i = 0; i < celda.length; i++) {
                                  if(celda[i].className!=undefined && celda[i].className.indexOf("columnaActual") != -1 ){
                                      celda[i].innerHTML = row.actual;
                                  }
                                  if(celda[i].className!=undefined && celda[i].className.indexOf("columnaComprometido") != -1 ){
                                      celda[i].innerHTML = row.comprometido;
                                  }
                              }
                          }

                      window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                          color: 'success'
                      });
                      //this.items = [];
                      //this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                      })
                      .catch(error => {
                        window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                            color: 'error'
                        });
                      }).then(() => {              
                        window.dialogLoader.hide();
                      });      
                }
            });
            
        }        
    },
    parse_date(date) {
            if (!date) return null;
            return window.moment(String(date)).format("YYYY-MM-DD");
        },

    finalizar_traspasos: function(){
        window.dialogLoader.show('Espere un momento por favor..');
        let datos = {"usuario": this.$local_storage.get("sb_usuario")};
        window.axios
        .post(process.env.VUE_APP_REPORTES_URL + "/bodeguita/finalizar_traspasos/", datos)        
            .then(response => {
              console.log("Finalizar traspasos correcto");
            }).catch(error => {
              var mensaje = "Ocurrió un error al finalizar traspasos. Intente nuevamente.";
              if(error.response && error.response.data)
                mensaje =  error.response.data;
              this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: mensaje,
                  footer: ""
              });
            }).then(() => {
                this.items = [];
                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                this.getVentasComprometidas();
                this.get_traspasos_pendientes();
                window.dialogLoader.hide();
            });
    },
    finalizar_salida_ventas: async function(){
        var self = this;  
        this.$swal({
            type: "info",
            title: "Cuidado",
            text: "Se dará salida a la mercancía pendiente de todas las ventas que no han finalizado su entrega de mercancía. Asegúrese de realizar este proceso cuando ya no haya puntos de venta trabajando. ¿Desea continuar?",
            footer: "NOTA: Si hay puntos de venta trabajando esto podría ocasionar errores en los puntos de venta e inconsistencias en el inventario.",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar"
        }).then(async result => {
            if (result.value ) {

                window.dialogLoader.show('Espere un momento por favor..');                      
                var venta_numero = 0;
                await Promise.all(self.ventas_comprometidas.map(async (id_venta) => {
                    
                    await window.axios.get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_venta)
                    .then(async response => {
                      //console.log("Venta: ", id_venta);
                      var venta = response.data;
                      var errores = [];
                      
                      //Comparar las cantidades de cada articulo

                      //await Promise.all(venta.articulos.map(async (articulo) => {
                      for(var k in venta.articulos){                          
                          var articulo = venta.articulos[k];
                          
                          if(articulo.estatus=="Pendiente"){
                                  await self.finalizarComprometido(articulo, venta).then(result => {
                                      //nada
                                      //console.log("Finalizando articulo: "+ articulo.nombre_articulo+" venta: "+id_venta);
                                  }).catch(err => {
                                      //console.log(articulo.nombre_articulo);
                                      //console.log(err);
                                      try {
                                        if(err != "Lotes no encontrados"){//Si no encuentra el lote es que se borró por alguna razón, y entonces no se considera error
                                          errores.push(err);
                                        }  
                                      } catch (error) {
                                        //console.log("error en el try: ", error)
                                        errores.push(error); 
                                      }                                                                          
                                  });
                          }

                      }

                      if(errores.length == 0){
                          //En ventas el estatus se queda igual
                          venta.usuario_salida = self.$local_storage.get("sb_usuario");
                          venta.fecha_salida = window.moment().format("YYYY-MM-DDTHH:mm:ss"); 
                          venta.observacion_salida = "Se dió salida masiva desde opción de Inventario Comprometido";

                          await window.axios
                              .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + venta._id + '?conflicts=true', venta)
                              .then(response => {
                                  venta._rev = response.data.rev;
                                  //console.log("Guardando estatus venta: ",id_venta);
                              })
                              .catch(error => {
                                console.log(error);                          
                              });
                      }

                    });

                }));

                this.items = [];
                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                this.getVentasComprometidas();
                this.get_traspasos_pendientes();
                window.dialogLoader.hide();
                    
            }
        });
        
      },      

    finalizarComprometido: function(articulo, venta){
        var self = this;
       return new Promise(function(resolve, reject) {
          var lotes = articulo.lotes;
          //Afectar inventario origen
          window.funciones_lotes.finalizarComprometido(lotes).then(result =>{              

                var art = venta.articulos.find(e => e.id_articulo == articulo.id_articulo);
                if (art){
                  art.lotes = result;
                  art.estatus = "Salida";
                  art.cantidad_salida = art.cantidad;
                }               

                window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + venta._id + '?conflicts=true', venta)
                    .then(response => {
                        venta._rev = response.data.rev;
                        //console.log("Guardando venta: ", venta._id);
                        return resolve(true);
                    })
                    .catch(error => {
                        //console.log("Error al finalizar", articulo.id_articulo)
                        //console.log(error)
                        return reject(error);
                    });
            }).catch( error => {
                //console.log(error)
                return reject(error);
            });
       });
    },
    
  }
}
</script>
